import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Home from '../Home';
import Musings from '../Musings';
import Musing from '../Musing';
import Projects from '../Projects';
import About from '../About';
import PWDGen from '../PWDGen';
// import Auth from '../Auth';
// import Firebase from '../Firebase';
// import CookieProvider from '../CookieProvider';
// const firebase = new Firebase();
// const db = firebase.db

function LoggedInOutNavElements(props) {
    return (<React.Fragment>
        <Nav.Link href="/musings">Musings</Nav.Link>
        <Nav.Link href="/projects">Projects</Nav.Link>
        <Nav.Link href="/about">About</Nav.Link>
    </React.Fragment>)
    // if(!props.isLoggedIn) {
    //     return (<Nav.Link href="/login">Login</Nav.Link>)
    // }
    // return (
    //     <React.Fragment>
    //         <Nav.Link href="/" onClick={() => {
    //             firebase.auth.signOut()
    //             CookieProvider.set('uid', null)
    //             CookieProvider.set('email', null) }}>
    //             Logout
    //         </Nav.Link>
    //     </React.Fragment>
    // )
}

// function PrivateRoute({ component: Component, ...rest }) {
//     return (
//         <Route
//             {...rest}
//             render={props =>
//                 Auth.isLoggedIn() ? (
//                     <Component {...props} />
//                 ) : (
//                         <Redirect
//                             to={{
//                                 pathname: "/login",
//                                 state: { from: props.location }
//                             }}
//                         />
//                     )
//             }
//         />
//     );
// }

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasDeterminedAuthStatus: false, isLoggedIn: false };
    }

    render() {
        var isLoggedIn = this.state.isLoggedIn;
        return (
            <Router>
                <Navbar expand="lg">
                    <Navbar.Brand href="/"><h4>Ross Patman</h4></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {/* <Nav className="mr-auto"></Nav> */}
                        <Nav>
                            <LoggedInOutNavElements isLoggedIn={isLoggedIn} />
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Container className="mainContainer">
                    <Route exact path="/" component={Home} />
                    <Route exact path="/musings" component={Musings} />
                    <Route exact path="/musings/:id" component={Musing} />
                    <Route exact path="/projects" component={Projects} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/gen" component={PWDGen} />
                    {/* <PrivateRoute exact path="/protected" component={Auth} /> */}
                    {/* <Route exact path="/login" component={Auth} /> */}
                </Container>
            </Router>
        )
    }
    /*
    componentDidMount() {
        // this.getUser();
    }

    getUser() {
        var self = this;
        firebase.auth.onAuthStateChanged(function(user) {
            if(user) {
                self.setState({ hasDeterminedAuthStatus: true, isLoggedIn: user !== null })
                CookieProvider.set('uid', user.uid);
                CookieProvider.set('email', user.email);
                self.autoCreateUser(user);
            }
        });
    }

    autoCreateUser(user) {
        if (user.displayName) return;
        const displayName = user.email.split("@")[0]
        var userData = {
            displayName: displayName
        }
        db.collection("users").doc(user.uid).set(userData)
        .then(function() {
            return user.updateProfile({
                displayName: displayName,
            })
        });
    }*/
}

export default App;