import React from 'react';
import {Helmet} from "react-helmet";
var md5 = require('md5');

class About extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <meta name="robots" content="noindex"></meta>
                </Helmet>
                <button onClick={() => { this.setState({ type: this.state.type === "text" ? "password" : "text" }) }}>{this.state.type === "text" ? "Hide" : "Show" }</button>
                <input type={this.state.type} onChange={this.inputChange}/>
                <button onClick={() => { window.location.reload() }}>Done</button>
                <p>{this.state.output}</p>
            </>
        )
    }

    constructor(props) {
        super(props);
        this.state = { output: "", type: "text" };
    }

    inputChange = (event) => {
        var input = event.target.value
        if(!input || input.length === 0) return
        var hashed = md5(input)
        navigator.clipboard.writeText(hashed)
        this.setState({output: hashed});
    }
}

export default About;