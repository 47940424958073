import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function IsInternalLink(link) {
    return link.startsWith("/");
}

function InternalExternalLink(props) {
    return (<a href={props.link} target={IsInternalLink(props.link) ? "_self" : "_blank"} rel={IsInternalLink(props.link) ? "prev" : "noopener noreferrer"}>{props.contents}</a>);
};

function Image(props) {
    return (<img src={props.src} alt={props.alt} width="300" height="187" />)
}

class PostView extends React.Component {
    render() {
        return (
            <article className="postView">
                <Row className="postRow">
                    <Col md={6}>
                        <div className="postImageHolder">
                            {this.props.link == null ?
                                <Image src={this.props.imgSrc} alt={this.props.imgAlt} />
                            :
                                <InternalExternalLink link={this.props.link} contents={<Image src={this.props.imgSrc} alt={this.props.imgAlt} />} />
                            }
                        </div>
                    </Col>	    			
                    <Col md={6}>
                        {this.props.title == null ? null : <h4>{this.props.title}</h4> }
                        <p>{this.props.text}</p>
                        {
                            this.props.readMores == null ? null :
                            this.props.readMores.map((readMore, index) => {
                                return (<a key={"readMore"+index} href={readMore.link} target="_blank" rel="noopener noreferrer" className="readMoreLink">{readMore.title}</a>)
                            })
                        }
                    </Col>
                </Row>
                { this.props.showSeparator ? null : <div className="postRowSeparator"></div> }
            </article>
        )
    }
}

export default PostView;