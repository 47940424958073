const musings = [
    {
        id: "simple-strategies-to-help-you-take-control-of-your-phone",
        imgSrc: "/img/musings/simple-strategies-to-help-you-take-control-of-your-phone.png",
        imgAlt: "Photo of an iOS home screen",
        title: "Simple Strategies to Help you Take Control of Your Phone",
        summary: "3 simple to implement strategies to make your phone work for you instead of against you."
    },
    {
        id: "anything-you-want-derek-sivers-summary",
        imgSrc: "/img/musings/anything-you-want-derek-sivers-summary.jpg",
        imgAlt: "Photo of Derek Sivers",
        title: "8 Lessons From 'Anything You Want' by Derek Sivers",
        summary: "My top learnings from this great short book on entrepreneurship. Written by the founder of CD Baby and based on his successes and failures running that business before selling it for $22m."
    }
]

class MusingsRepository {
    all = musings;
    getMusing(id) {
        var musing = null
        this.all.forEach(m => { if(m.id === id) { musing = m } });
        return musing
    }
}

export default MusingsRepository;