import React, {Suspense} from 'react';
import {Helmet} from "react-helmet";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MusingsRepository from '../../repositories/musings';
import PageTitleView from '../PageTitleView';

class Musing extends React.Component {
    render() {
        return(
            <div className="musingContainer">
                <Helmet>
                    <title>Ross Patman - {this.musing.title}</title>
                    <meta name="description" content={this.musing.summary}/>
                </Helmet>
                {this.musing === null ? null :
                    <>
                        <Row>
                            <Col sm={7}>
                                <PageTitleView emojiAlt="Writing hand emoji" emoji="✍️" title={this.musing.title} subtitle="Ross Patman" />
                            </Col>
                            <Col sm={5}>
                                <img src={this.musing.imgSrc} alt={this.musing.imgAlt} className="featuredImage"/>
                            </Col>
                        </Row>
                        <Suspense fallback={<div>Loading...</div>}>
                            <this.Contents />
                        </Suspense>
                        <p></p>
                    </>
                }
            </div>
        )
    }
    constructor(props) {
        super(props);
        const { params } = this.props.match;
        this.musing = new MusingsRepository().getMusing(params.id)
        this.Contents = React.lazy(() => import('../../repositories/musings/contents/'+params.id));
    }
}

export default Musing;