import React from 'react';
import {Helmet} from "react-helmet";
import PostView from '../PostView';
import MusingsRepository from '../../repositories/musings';
import PageTitleView from '../PageTitleView';

class Musings extends React.Component {
  render() {
    return (
      <>
        <Helmet>
            <title>Ross Patman - Musings</title>
        </Helmet>
        <PageTitleView emojiAlt="Writing hand emoji" emoji="✍️" title="Musings" subtitle="You'll find assorted nonsense below" />
        <p>You can find Anibles related musings on the <a href="https://blog.anibles.io">Anibles blog</a>.</p>
        {
            this.musings.map((musing, index) => {
                return (
                    <PostView key={musing.id} title={musing.title} link={"/musings/"+musing.id} imgSrc={musing.imgSrc} imgAlt={musing.imgAlt} text={musing.summary} readMores={musing.readMores} showSeparator={index === this.musings.length-1}/>
                )
            })
        }      
      </>
    );
  }

  constructor(props) {
    super(props);
    this.musings = new MusingsRepository().all
  }
}

export default Musings;
