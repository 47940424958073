import React from 'react';
import PageTitleView from '../PageTitleView';

class Home extends React.Component {
  render() {
    return (
      <div className="homeContainer">
        <PageTitleView emojiAlt="Waving hand emoji" emoji="👋" title="Hello" subtitle="Nice to meet you!" />
        <p>With the formalities dispensed of, I now officially welcome you to my digital playground.</p>
        <p>You may wish to view the <a href="/musings">musings</a> of a man who has lost his furniture, or find out about the <a href="/projects">projects</a> that are born of his ill-conceived notions.</p>
        <p>If neither of those options sound appealing, below you'll find a picture of the animal that currently fascinates me the most, the saltwater crocodile.<br/>Feel free to marvel at its gargantuan size and prehistoric characteristics, and/or wonder if it's <a href="https://youtu.be/I7fZZUfvx0s" target="_blank" rel="noopener noreferrer">capable of feeling emotions</a> like love and sadness.</p>
        <img className="featureImage" src="img/home/saltwatercrocodile.jpg" alt="Saltwater crocodile head protruding from river"></img>
      </div>
    );
  }
}

export default Home;
