import React from 'react';
import {Helmet} from "react-helmet";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageTitleView from '../PageTitleView';

class About extends React.Component {
    render() {
        return (
            <div className="aboutContainer">
                <Helmet>
                    <title>Ross Patman - About</title>
                </Helmet>
                <PageTitleView emojiAlt="Man using laptop emoji" emoji="👨‍💻" title="About" subtitle="Who is this guy anyway?" />
                <Row>
                    <Col>
                        <p>I'm a technologist on a mission to help nature.</p>
                        <p>I currently reside just outside London, England.</p>
                        <p>If you're stalking me on Google, be warned that there is more than one Ross Patman, and I'm not the one in the news for the wrong reasons!</p>
                        <p>You might find me reading, getting steamrolled at Brazilian Jiu-Jitsu, atoning for my sins in a gym, or playing PC games.</p>
                        <p><a href="https://uk.linkedin.com/in/rosspatman" target="_blank" rel="noopener noreferrer">LinkedIn</a> | <a href="https://instagram.com/rossrossp" target="_blank" rel="noopener noreferrer">Instagram</a> | <a href="https://x.com/RossPatman" target="_blank" rel="noopener noreferrer">Twitter</a></p>
                    </Col>
                </Row>
                <Row className="skills">
                    <Col xs={12}><h4>Skills</h4></Col>
                    {Object.keys(skills).map((key) => {
                        return (
                            <Col sm={3} key={key}>
                                <h5>{key}</h5>
                                {skills[key].map((skill) => {
                                    return (
                                        <p key={skill.name}>{skill.name}</p>
                                    )
                                })}
                            </Col>
                        )
                    })}
                </Row>
            </div>
        )
    }
}

const skills = { 
    "Mobile": [
        { name: "Swift", description: "Apple's new programming language. Safer, faster, more concise and more powerful. Person.find(byId: 1).upgradeToSwift()" },
        { name: "SwiftUI", description: "Apple's powerful new mechanism for building user interfaces across their platforms." },
        { name: "Objective-C", description: "Apple's older programming language. [[Person findById: 1] upgradeToSwift];" },
        { name: "RxSwift", description: "The Swift implementation of Reactive programming. Allows code to respond to changes without you telling it to do so." },
        { name: "CoreData", description: "The default and most common database solution for iOS apps." },
        { name: "SpriteKit/SceneKit", description: "Used to create and display complex graphics and animations within iOS apps." },
        { name: "AVKit/AVFoundation", description: "Used to provide and manipulate audio and visual media within iOS apps." },
        { name: "XCTest", description: "The native unit testing framework within Xcode." },
        { name: "Xcode Server", description: "Apple's native solution to continous integration using bots." },
        { name: "AFNetworking/Alamofire", description: "A popular networking libraries for Objective-C and Swift." },
        { name: "SwiftyJSON", description: "A great little JSON parsing library for Swift, although Swift 4's new native mechanism can do a lot of the same things." },
        { name: "Flutter", description: "A cross-platform development technology from Google."},
        { name: "Capacitor/Ionic" },
        { name: "MVC/MVVM/VIP", description: "Design patterns popular in mobile development."},
        { name: "Clean Architecture", description: "A way of organising software to be understandable, malleable, and extensible."}
    ],
    "Web": [
        { name: "HTML/CSS", description: "The standard technologies for building websites." },
        { name: "JavaScript/TypeScript", description: "Front-end technologies used to create dynamic websites." },
        { name: "React", description: "An open source JavaScript framework maintained by Facebook, which aims to make it painless to create interactive UIs." },
        { name: "Next.js" },
        { name: "Tailwind" },
        { name: "Astro.js" },
        { name: "React Three Fiber/Drei" },
        { name: "Mapbox" },
        { name: "AngularJS", description: "An open source JavaScript framework maintained by Google, which helps web-apps to become more dynamic. Even this page uses it!" },
        { name: "Bootstrap", description: "An open-source framework for designing websites. Allows for rapid development of consitent, responsive websites." },
        { name: "Less.js", description: "A dynamic stylesheet language which makes CSS easier to write, and more powerful." },
        { name: "WordPress", description: "An open-source CMS build on PHP and MySQL. Powers around 1/4 of the web." }
    ],
    "Backend": [
        { name: "Node.js", description: "A JavaScript run-time environment which allows JS code to run server-side. Essentially, you can build backend technologies with JavaScript now." },
        { name: "Supabase" },
        { name: "Firebase/GCP", description: "A mobile and web app development platform owned by Google. Experience with Analytics, Hosting, Firestore, Storage, and Functions." },
        { name: "Vercel" },
        { name: "AWS Lambda", description: "A serverless computing platform from Amazon. Code runs in response to events. Very scalable." },
        { name: "AWS API Gateway", description: "The AWS solution to building APIs. Couple with Lambda for a great serverless architecture." },
        { name: "AWS DynamoDB", description: "A fast and scalable NoSQL database solution from Amazon." },
        { name: "AWS User Pools", description: "Allows implementation of user authentication and related workflows without having to manage sensitive data." },
        { name: "AWS Elastic Beanstalk/EC2", description: "The AWS solution to deploying and scaling web applications." },
        { name: "AWS CloudSearch", description: "Upload and search documents in the cloud." },
        { name: "AWS SNS", description: "Amazon's push notification service." },
        { name: "AWS S3", description: "Used for storage of files. Can even be used to serve websites." },
        { name: "AWS CloudFormation", description: "Used to deploy and manage AWS services, which can be declared as code and therefore version controlled." },
        { name: "REST APIs/JSON", description: "The most common way interface between systems, and a standard way to format data." },
        { name: "PHP", description: "The most common server-side programming language." },
        { name: "Postgres" },
        { name: "Sentry" },
        { name: "Express.js" },
        { name: "MySQL", description: "The world's most popular open-source database." },
        { name: "Java", description: "My first programming language. Although I haven't used it much recently, it still holds a special place in my heart." },
    ],
    "Other": [
        { name: "Ethereum Blockchain/Solidity", description: "A decentralised platform for applications. Aimed at eliminating fraud, censorship and third party interference." },
        { name: "Photoshop/Affinity Photo", description: "Popular tools for image editing." },
        { name: "Illustrator/Affinity Designer", description: "Popular tools for dealing with vector image." },
        { name: "Sketch", description: "For creating wireframes." },
        { name: "Git", description: "The standard version control for software projects and more." },
        { name: "Agile - Scrum/Kanban", description: "Iterative methodologies for managing engineering activities." },
        { name: "JIRA", description: "A popular tool for planning, tracking and managing agile software projects." },
        { name: "Buddybuild", description: "A great CI and CD platform for iOS." },
        { name: "Bitrise", description: "A great CI and CD platform for iOS and Android." },
        { name: "CircleCI" },
        { name: "Bitbucket Pipelines", description: "CI from within the same place as where the code is stored, and with no support for iOS." },
        { name: "TDD", description: "Requirement -> test case -> minimum code to pass -> refactor -> repeat." },
        { name: "Unity/C#", description: "A popular cross-platform game engine, and a scripting language for it. I am currently using them to build a conservation simulation game." },
        { name: "Blender", description: "An open-source tool for creating animations, models, visual effects, and other 3D assets. I am using it to build the models and animations for the above." }
    ]
}

export default About;