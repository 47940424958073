import React from 'react';
import {Helmet} from "react-helmet";
import PostView from '../PostView';
import PageTitleView from '../PageTitleView';

class Projects extends React.Component {
  render() {
    return (
      <>
        <Helmet>
            <title>Ross Patman - Projects</title>
        </Helmet>
        <PageTitleView emojiAlt="Construction tools emoji" emoji="🛠" title="Projects" subtitle="Let's see what I've been up to" />
        {
            projects.map((project, index) => {
                return (
                    <PostView key={"project"+index} link={project.link} imgSrc={"img/projects/"+project.imgSrc} imgAlt={project.alt} text={project.text} readMores={project.readMores} showSeparator={index === projects.length-1}/>
                )
            })
        }
      </>
    );
  }
}

const projects = [
    {
        link: "https://anibles.io",
        imgSrc: "home-cover.jpg",
        alt: "Screenshot of game showing rhino and gorilla model",
        text: "I'm currently working on an outdoor wildlife RPG. The game is built with Capacitor, and 3D models and animations are made with Blender. The Anibles themselves are modular, allowing for tens of millions of unique Anibles across 64 species to be generated. By simply walking and discovering Anibles, players will raise money for wildlife causes. Website built with Next.js."
    },
    {
        imgSrc: "wildya.png",
        link: "https://wildya.earth/wildya-app/",
        alt: "Screenshot of the Wildya website, showing a man in a hat against a nature background",
        text: "I built and released the MVP of the Wildya app to the App and Play stores, to help the founder get it off the ground. It is an app which helps people with their eco-anxiety, and contributes to nature restoration. The frontend is built with FlutterFlow and the backend with Firebase. In-app subscriptions use RevenueCat."
    },
    {
        link: "https://thepurecardco.web.app/",
        imgSrc: "ThePureCard.png",
        alt: "Picture of earth-friendly greeting card in use",
        text: "Did you know that the average UK household buys 30 greeting cards per year? This website is for a venture that sells plastic-free recycled greeting cards, as opposed to the non-eco friendly nature of most greeting cards currently being sold."
    },
    {
        imgSrc: "coronavirus.png",
        alt: "Coronavirus Tracker screenshots",
        text: "Stuck inside during coronavirus lockdown? Let's spend 12 hours building a COVID statistics tracker! I thought I could monetise it and give any profits to the relief effort. However, the Apple review team had other ideas, as to release an app for healthcare purposes you must be a recognised healthcare organisation. Still, I got some experience with web scrapers SwiftUI. Data uploaded to Firestore to protect the app from 3rd party API changes and limitations.",
        readMores: [
            {
                title: "📺 Download demo video",
                link: "media/CoronavirusTracker.mov"
            }
        ]
    },
    {
        imgSrc: "Alchemis.png",
        alt: "Screenshot of a charity app",
        text: "The purpose of this app is to raise money through ad revenue to aid conservation causes. The user can choose which cause to help, learn about it, and see their positive impact on it in the form of points. Built with Swift + Google AdMob."
    },
    {
        link: "https://apps.apple.com/us/app/la-galaxy/id1248986173",
        imgSrc: "lagalaxy.png",
        alt: "LA Galaxy screenshots",
        text: "Get closer to the most successful team in Major League Soccer with the all new and improved LA Galaxy app. The first app with an end-to-end AXS SSO -> AXS SDK solution. Deployed using the automation tool I built for LiveStyled, which allows the re-skin of whitelabel apps through a website built with React + Firebase."
    },
    {
        link: null,
        imgSrc: "tenkay.png",
        alt: "Tenkay screenshots",
        text: "An app which allows users to log, visualise, and gamify time spent on skill acquisition, helping them to push towards mastery at 10,000 hours. Built in Swift with TDD and a variation of the VIP design pattern which addresses some issues of non-conformance with Clean Architecture as proposed by Robert C. Martin."
    },
    {
        link: null,
        imgSrc: "minideck.png",
        alt: "Minideck website screenshot",
        text: "A prototype of a tool that I believe could save time spent in meetings by replacing them with concise decks of cards. Features team management and viewership analytics. Built with React and Firestore, hosted on Firebase, with magic link signin."
    },
    {
        link: "https://apps.apple.com/gb/app/firefly-festival-2019/id538104162",
        imgSrc: "firefly.png",
        alt: "Firefly Festival logo",
        text: "The official app for Firefly Music Festival 2019 @ Dover International Speedway, Delaware, USA. Upwards of 50,000 attendees over the weekend, with wristband registration through the app, and a 95/5 iOS/Android split."
    },
    {
        link: "https://itunes.apple.com/us/app/spurs-official-app/id1407312851?ls=1&mt=8",
        imgSrc: "thfc.png",
        alt: "THFC app screenshots",
        text: "The official app for Tottenham Hotspur Football Club. It reached the number 1 spot in the sports category, and number 15 overall, 24 hours after launch. Has integrated ticketing using THFC's SSO, results, fixtures, countdowns, video and more. We are seeing upwards of 8000 concurrent users on matchdays.",
        readMores: [
            {
                title: "🗞 THFC official feature",
                link: "https://www.tottenhamhotspur.com/spurs-official-app/"
            }
        ]
    },
    {
        link: null,
        imgSrc: "conslt.png",
        alt: "Conslt logo",
        text: "Co-created a startup for online professionals.\nDesigned and built the website, API and supporting infrastructure, as well as performing UX and business functions.\nStack includes: (AWS) Lambda, API Gateway, Elastic Beanstalk, DynamoDB, User Pools, ElastiCache, CloudSearch, SNS and S3. Written mostly in NodeJS and managed with CloudFormation.",
        readMores: [
            {
                title: "📺 Web demo",
                link: "https://rp-app-resources.s3-eu-west-1.amazonaws.com/conslt/demo/web.mov"
            },
            {
                title: "📺 iOS demo",
                link: "https://rp-app-resources.s3-eu-west-1.amazonaws.com/conslt/demo/iOS.mov"
            }
        ]
    },
    {
        link: "https://itunes.apple.com/gb/app/the-o2/id1272123607?mt=8",
        imgSrc: "theo2.png",
        alt: "The O2 logo",
        text: "The official app for the most popular music and enterainment venue in the world.\nThe app can show your tickets, so you only need to flash your phone to get in. It has social sections that let you interact and share photos with other fans. And it brings all the exclusive deals and special food and drink offers into your pocket."
    },
    {
        link: "http://www.adweek.com/creativity/bentleys-new-app-uses-facial-recognition-point-you-ideal-suv-167885/",
        imgSrc: "bentley-inspirator3.png",
        alt: "Bentley Inspirator logo",
        text: "A vehicle personalisation app for the Bentley Bentayga which uses emotion recognition to measure reactions to media in order to create a custom journey and ultimately suggest a vehicle to the user.\nCombines both SpriteKit and SceneKit for animations.\nWinner of the 'Best Mobile App' BIMA award.",
        readMores: [
            {
                title: "🎥 Colleague Showcase & Promo Video",
                link: "https://www.sambathe.com/Bentley-Inspirator-App"
            },
            {
                title: "🏅 BIMA Mobile Award Winner",
                link: "http://bimaawards.com/winners/bentley-inspirator/"
            },
            {
                title: "🗞 Bentley Newsroom Feature",
                link: "https://www.bentleymedia.com/en/newsitem/46-bentley-inspirator-personal-preference-brought-to-life"
            },
            {
                title: "🗞 Luxurious Magazine Feature",
                link: "https://www.luxuriousmagazine.com/bentley-inspirator-smile-your-way-to-a-dream-bentley/"
            }
        ]
    },
    {
        link: "https://itunes.apple.com/gb/app/mercedes-benz-arena-berlin/id1372886527?mt=8",
        imgSrc: "mba.png",
        alt: "MBA logo",
        text: "Whether visiting for a concert or a show, a fan of the Eisbären Berlin or ALBA BERLIN - this is the essential companion for a visit to the Mercedes-Benz Arena."
    },
    {
        link: "https://itunes.apple.com/us/app/t-app-telenor-arena/id1275497111?mt=8",
        imgSrc: "telenor.png",
        alt: "Telenor Arena logo",
        text: "A multi-purpose app for the 15,000 capacity indoor Telenor Arena, just outside Oslo, Norway.\nWith further developments to LiveStyled's dynamic widget system, and translated into Norwegian."
    },
    {
        link: "https://itunes.apple.com/us/app/thecompass/id1280287847?mt=8",
        imgSrc: "thecompass.png",
        alt: "TheCompass logo",
        text: "A simple app coded in Swift, using the Functional Programming paradigm, which allows users to record their dietary habits. Further developments, including analysis and behaviour alteration to come!"
    },
    {
        link: "https://apps.apple.com/gb/app/the-sse-arena-wembley/id1290133388",
        imgSrc: "ssearenawembley.png",
        alt: "SSE Arena Wembley logo",
        text: "The official app for the SSE Arena Wembley.\nThe app can show tickets via an AXS integration, and gives access to special food and drink offers available through dedicated app queues at the venue",
        readMores: [
            {
                title: "🗞 Official Feature",
                link: "https://www.ssearena.co.uk/info/venue-app"
            }
        ]
    },
    {
        link: "https://itunes.apple.com/gb/app/virgin-active-uk/id1005816652?mt=8",
        imgSrc: "va.png",
        alt: "Virgin Active logo",
        text: "A class booking app for Virgin Active UK.\nAllows users to view their booked classes and book/cancel/join waiting list of classes over a 7 day period.\nUses CoreData to allow data to be viewed offline."
    },
    {
        link: null,
        imgSrc: "watchatlunch.png",
        alt: "Watch at Lunch screenshot",
        text: "I watch at least one video a day to learn things and improve myself. I decided that I would journal them and make them accessible to other people via an app and website, with automated social media accounts giving visibility. Website hosted on Lambda, and powered by an external NoSQL database."
    },
    {
        link: null,
        imgSrc: "simpleworkoutplanner.png",
        alt: "Simple Workout Planner screenshot",
        text: "I couldn't find a workout planner/tracker which suited me. I don't want to plan and record every little detail, as it's overkill, and my workouts often change. I do, however, want to see when I improve and hit PBs. I also want to know what workout is next based on what I haven't done for the longest. So I knocked up this useful (for me anyway!) little app."
    },
    {
        link: "https://events-9df0f.web.app/",
        imgSrc: "applepieevents.png",
        alt: "Tropical Party Cocktails website screenshot",
        text: "A website for an cocktail bar company. Built with React, hosted on Firebase, domain managed through AWS Route 53."
    },
    {
        link: null,
        imgSrc: "sqwish.png",
        alt: "Sqwish.it screenshot",
        text: "I like to summarise books that I read. I wanted to have an easier, standardised way to create, access and share said summaries. I thought other people might also find this useful. I also wanted to try building a website with AWS Lambda, so I found a way to deploy Express.js to a Lambda function. In the process, I created a mechanism to generate similar projects with just a few commands."
    },
    {
        link: "other/tracking/index.html",
        imgSrc: "ethereum.png",
        alt: "Ethereum logo",
        text: "An ownership tracking prototype ÐApp. I had thoughts that it could be used to force people to be responsible for their litter, by tracking who each piece belongs to.\nYou'll need the Metamask plugin to use the app, with an account containing gas on the Rinkeby test network to write to the blockchain."
    },
    {
        link: "https://itunes.apple.com/us/app/tele2-arena/id685278845?mt=8",
        imgSrc: "tele2.png",
        alt: "Tele2 logo",
        text: "A multi-purpose app for the 45,000 capacity music and sports venue, Tele2 Arena, in Stockholm.\nUses the new 'Widget' system I helped build for LiveStyled, and translated into Swedish."
    },
    {
        link: "https://itunes.apple.com/gb/app/sse-arena-belfast/id1030458447?mt=8",
        imgSrc: "sse.png",
        alt: "SSE Arena Belfast logo",
        text: "The official iOS app for the SSE Arena in Belfast.\nWinner of the 'App of the Year' at the DANI (Digital Awards Northern Ireland) Awards"
    },
    {
        link: "https://wsrrecruitment.web.app",
        imgSrc: "wsr.png",
        alt: "WSR Recruitment logo",
        text: "A recruitment website built with the PHP framework Laravel along with Bootstrap and jQuery.\nFull Job posting and Industry Sector control through a custom CMS.  (Temporary link)."
    },
    {
        link: "http://greyfuturefilm.com.gridhosted.co.uk/",
        imgSrc: "greyfuturefilm.png",
        alt: "Grey Future Film logo",
        text: "A website to showcase a friend's film to raise awareness on the conservation issues faced by rhinos and elephants, an issue close to my heart. (Temporary link)."
    },
    {
        link: "http://www.infuse.it",
        imgSrc: "infuse.png",
        alt: "Infuse Consulting logo",
        text: "A website to showcase an awesome Test Automation Consultancy.\nBuilt on top of WordPress using a heavily customised responsive theme."
    }
]

export default Projects;
