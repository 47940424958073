import React from 'react';

class PageTitleView extends React.Component {
    render() {
        return (
            <div className="pageTitleView">
                <h1><span role="img" aria-label="Writing hand emoji">{this.props.emoji} </span>{this.props.title}</h1>
                <h2>{this.props.subtitle}</h2>
            </div>
        )
    }
}

export default PageTitleView;